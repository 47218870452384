<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="KYC Entity Detail" icon="address-card" />

    <div class="generalBox border shadow-sm">
        <div v-if="isLoading === true" class="row">
            <div class="col-12">
                <fa icon="spinner" size="lg" class="me-2" />Loading...
            </div>
        </div>

        <div v-if="isLoading === false" class="row">
            <div class="col-12 fw-bold pb-3">
                <table width="100%">
                    <tr>
                        <td>
                            <Popper class="popperDark" arrow hover content="Back">
                                <fa icon="angle-left" size="lg" class="me-3 isLink" @click="$router.push({ path: '/backoffice/ekyc' })" />
                            </Popper>
                            Organization Information
                        </td>
                        <td width="80px" class="text-end">
                            <span @click="showLog = !showLog" class="text-grey isLink">
                                <fa icon="file-lines" size="lg" />
                            </span>
                        </td>
                    </tr>
                </table>
            </div>

            <div class="col-12 pb-3 text-center">
                <div class="row">
                    <div class="col-lg-3 mb-2">
                        <span v-if="entity.jLogo">
                            <ImgLogo :id="entity.jLogo.entityId" :isVerified="entity.isVerified" :width="entity.jLogo.width" :height="entity.jLogo.height" />
                            <!-- <Logo :url="entity.jLogo.imgBase64" :width="entity.jLogo.width" :height="entity.jLogo.height" /> -->
                        </span>
                    </div>

                    <div class="col-lg-9 text-start">
                        <div class="mb-2">
                            {{entity.status}}
                            <span class="float-end">
                                {{entity.kycStatus}}
                                <span v-if="entity.kycRemark !== ''" class="ms-2">
                                    <Popper class="popperDark" arrow hover :content="'Rejected reason: ' + entity.kycRemark">
                                    <fa icon="clipboard" class="isLink" />
                                    </Popper>
                                </span>
                                <span v-if="entity.kycNote !== ''" class="ms-2">
                                    <Popper class="popperDark" arrow hover :content="'Private note: ' + entity.kycNote">
                                    <fa icon="note-sticky" class="isLink" />
                                    </Popper>
                                </span>
                            </span>
                        </div>
                        <div class="mb-2">
                            <span class="fw-bold">{{entity.entityName}}</span>
                        </div>

                        <div class="mb-2">
                            <div><span class="badge bg-secondary text-uppercase">{{entity.plan}}</span></div>
                            <div v-if="entity.dateCreate === '' || entity.dateCreate === null || entity.dateCreate === undefined || entity.dateCreate === 0 ">joined <span class="small text-grey">{{func.convDateTimeFormat(entity.dateModify, 'text')}}</span></div>
                            <div v-else>joined <span class="small text-grey">{{func.convDateTimeFormat(entity.dateCreate, 'text')}}</span></div>
                        </div>
                    </div>
                </div>
            </div>

        </div> 

        <div v-if="isLoading === false && showLog" class="row px-3">
            <div class="contentBox border">
                <div class="mb-2 text-grey fw-bold">History Logs</div>
                <div class="text-grey">
                    <table width="100%">
                        <tr v-for="log in arrLog" :key="log">
                            <td>
                                <div class="row mb-2">
                                    <div class="col-lg-3">{{func.convDateTimeFormat(log.date, 'datetime')}}</div>
                                    <div class="col-lg-9">{{log.description}}</div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div v-if="isLoading === false" class="row px-3 mb-0">
            <div class="contentBox border">
                <div class="row">
                    <div class="col-12 col-lg-6 mb-4">
                        <div class="lblTiny">Name of organization</div> 
                        <div>{{ entity.entityName }}</div>
                    </div>

                    <div class="col-12 col-lg-6 mb-4">
                        <div class="lblTiny">Registration ID</div> 
                        <div>{{ entity.registrationId }}</div>
                    </div>

                    <div class="col-12 col-lg-6 mb-4">
                        <div class="lblTiny">Country</div> 
                        <div>{{ entity.jAddress.countryName }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="isLoading === false" class="generalBox border shadow-sm mt-0">
        <div class="row">
            <div class="col-12 fw-bold pb-2">Organization Document</div>

            <div v-if="entity.jKYC && entity.jKYC.length === 0" class="mb-4">Image not found</div>    
            <div v-for="img in entity.jKYC" :key="img" class="col-12 mb-4 mt-2">
                <div v-if="img.mimeType !== 'application/pdf'">
                    <fa icon="magnifying-glass-plus" size="lg" class="ms-1 isLink" @click="imgSrc = img.imgBase64; imgMime = img.mimeType" data-bs-toggle="modal" data-bs-target="#mdlShowImg"/>
                </div>
                <div v-if="img.mimeType === 'application/pdf'">
                    <span class="isLink" @click="downloadKyc(img.dlUrl)"><fa icon="file-pdf" class="me-2" />Download</span>
                </div>
                <div v-else>
                    <ImgBOKycEntity :id="img.id" :entity="entity.entityId" :width="img.width" :height="img.height" label="kyc" />
                    <!-- <img :src="'data:' + img.mimeType + ';base64, ' + img.imgBase64" class="img-fluid" /> -->
                </div>
                <div><hr /></div>
            </div>
            
            <div class="col-12 text-center">
                <button class="btn btn-outline-secondary me-2" @click="$router.push({ path: '/backoffice/kyc' })">
                    <fa icon="angle-left" size="lg" class="me-2" />Back
                </button>
                <button v-if="entity.kycStatus === 'SUBMITTED'" data-bs-toggle="modal" data-bs-target="#mdlUpdStatus" @click=" kycStatus = 'PROCESS'" class="btn btn-secondary me-2">
                    <fa icon="user-gear" size="lg" class="me-2" />Process
                </button>
                <button v-if="entity.kycStatus === 'PROCESS'" data-bs-toggle="modal" data-bs-target="#mdlUpdStatus" @click=" kycStatus = 'REJECTED'" class="btn btn-secondary me-2">
                    <fa icon="user-xmark" size="lg" class="me-2" />Reject
                </button>
                <button v-if="entity.kycStatus === 'PROCESS'" data-bs-toggle="modal" data-bs-target="#mdlUpdStatus" @click=" kycStatus = 'APPROVED'" class="btn btn-secondary">
                    <fa icon="user-shield" size="lg" class="me-2" />Verify
                </button>
            </div>

        </div>
    </div>

    <!-- Modal : Show KYC Image --> 
    <div
        class="modal fade"
        id="mdlShowImg"
        tabindex="-1"
        aria-labelledby="ShowImg"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark modal-lg">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">Organization Document</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div style="overflow: auto;">
                        <div><img :src="'data:' + imgMime + ';base64, ' + imgSrc" class="img-fluid" /></div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 

    <!-- Modal --> 
    <div
        class="modal fade"
        id="mdlUpdStatus"
        tabindex="-1"
        aria-labelledby="UpdStatus"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 v-if="kycStatus === 'PROCESS'" class="modal-title"> Process</h5>
                    <h5 v-if="kycStatus === 'REJECTED'" class="modal-title"> Reject</h5>
                    <h5 v-if="kycStatus === 'APPROVED'" class="modal-title"> Verify</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">

                        <div v-if="kycStatus === 'PROCESS'" class="col-12">
                            Confirm to process this subscriber record within 24 hours.
                        </div>

                        <div v-if="kycStatus === 'REJECTED'" class="col-12">
                            <div class="mb-2">Rejected reason below will send to respective entity</div>
                            <div><textarea class="form-control mb-3" rows="5" v-model="rejectReasonPublic"></textarea></div>
                            <div class="mb-2">Private note</div>
                            <div><textarea class="form-control" rows="5" v-model="rejectReasonPrivate"></textarea></div>
                        </div>

                        <div v-if="kycStatus === 'APPROVED'" class="col-12">
                            Confirm to verify organization as KYC organization.
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button v-if="kycStatus === 'PROCESS'" @click="confirmProcess" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="arrow-pointer" class="me-2" />Confirm
                    </button>
                    <button v-if="kycStatus === 'REJECTED' && rejectReasonPublic !== ''" @click="confirmReject" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="arrow-pointer" class="me-2" />Confirm
                    </button>
                    <button v-if="kycStatus === 'VERIFIED'" @click="confirmApprove" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="arrow-pointer" class="me-2" />Confirm
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 

</template>

<script>
import { ref, inject, onMounted } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
// import Logo from '@/components/Logo.vue'
import ImgLogo from '@/components/ImgLogo.vue'
import ImgBOKycEntity from '@/components/ImgBOKycEntity.vue'

/* // Image Uploader for avatar
import vueFilePond from "vue-filepond"
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
) */

export default {
    name: 'BOEKycProfile',
    components: { Alert, TopNavigation, Popper, /* Logo, */ ImgLogo, ImgBOKycEntity },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const isLoading = ref(true)  
        const entityId = ref(null)
        const entity = ref({})
        const arrLog = ref([])
        const showLog = ref(false)
        
        const imgSrc = ref('')
        const imgMime = ref('')
        const kycStatus = ref(null)
        const rejectReasonPublic = ref('')  // Disclose to user - remark field
        const rejectReasonPrivate = ref('') // For SU only, not disclose to user - note field
        

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const downloadKyc = async (url) => {
            console.info('url dwnload', url)
            const existingPdfBytes = await fetch(url, { credentials: 'include' }).then(res => res.arrayBuffer())
            const blob = new Blob([existingPdfBytes], { type: "application/pdf" })
            const filename = 'entity_kyc.pdf'

            if (window.navigator['msSaveOrOpenBlob']) {
                window.navigator['msSaveBlob'](blob, filename)

            }
            else {
                const link = window.document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = filename
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

            }
        }

        const getEntity = async () => {
            try {
                const p = {
                    entityId: entityId.value,
                    showKYC: true
                }

                const res = await axios.post('/entity/su/entity', p)
                if (res.data.status === 1) {
                    // console.info('getEntity', JSON.stringify(res.data.data))
                    entity.value = res.data.data
                    arrLog.value = res.data.data.jKYCLog
                    
                } else {

                    func.addLog('BO:entityKycDetail', 'getEntity', res)

                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: func.getError(res.data)
                    })
                }

            } catch (error) {
                func.addLog('BO:entityKycDetail', 'getEntity - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                
            }
        }

        const confirmProcess = () => {

            const p = {
                entityId: entityId.value,
                action: 'PROCESS'
            }

            axios.put('/entity/su/verify', p)
                .then((res) => {
                    // console.info('confirmProcess res', JSON.stringify(res))
                    if (res.data.status === 1) {

                        getEntity()

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "The record is updated to process."
                        })

                    } else {

                        func.addLog('BO:entityKycDetail', 'confirmProcess', res)

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })
                    }
                })
                .catch((error) => {
                    func.addLog('BO:entityKycDetail', 'confirmProcess - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }
   
        const confirmApprove = () => {

            const p = {
                entityId: entityId.value,
                action: 'APPROVED'
            }

            axios.put('/entity/su/verify', p)
                .then((res) => {

                    if (res.data.status === 1) {

                        getEntity()
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "The record is updated to approved."
                        })

                    } else {
                        func.addLog('BO:entityKycDetail', 'confirmApprove', res)
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })
                    }
                })
                .catch((error) => {
                    func.addLog('BO:entityKycDetail', 'confirmApprove - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const confirmReject = () => {
            const p = {
                entityId: entityId.value,
                action: 'REJECTED',
                remark: rejectReasonPublic.value,
                note: rejectReasonPrivate.value
            }

            axios.put('/entity/su/verify', p)
                .then((res) => {
                    if (res.data.status === 1) {

                        getEntity()
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "The record is updated to rejected."
                        })

                    } else {

                        func.addLog('BO:entityKycDetail', 'confirmReject', res)
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })
                    }
                })
                .catch((error) => {
                    func.addLog('BO:entityKycDetail', 'confirmReject - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }
        
        onMounted(async () => {

            entityId.value = route.params.entityId
            await getEntity()
            isLoading.value = false
        })

        return {
            alert, closeAlert, router, func, store, isLoading, 
            entityId, entity, showLog, arrLog, kycStatus, imgSrc, imgMime, 
            confirmProcess, confirmApprove, confirmReject, rejectReasonPublic, rejectReasonPrivate, downloadKyc
        }
    },
    
}
</script>

<style>
.customLink {
    z-index: 10000; 
    padding-top: 70px; 
    opacity: 0.6;
    visibility: visible;
    background-color: white;
    width: 122px;
}

.editLink {
    font-size: 18px;
    padding: 8px; 
}

.editLink:hover {
    background-color: #DADADB;
    padding: 8px 10px;
    border-radius: 20px;
}
</style>